body {
  background-color: #FFF;
  font-family: Overpass;
  width: 100%;
  height: 100vh;
  background: rgb(229,229,230);
background: linear-gradient(225deg, rgba(229,229,230,1) 0%, rgba(223,223,228,1) 35%, rgba(246,246,246,1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}

p {
  font-family: Lato;
}
.App {
  text-align: center;
}
#sidebar {
  z-index: 100000;
}

.menu-item {
  font-family: Overpass;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  color: #474747;
  text-decoration: none;
  margin-top: 15px;
  outline: none;
  transition-property: color;
  transition-property: all;
  transition-duration: 0.25s;
}

.menu-item:hover {
  color: #692115;
  text-decoration: none;
}

.list-icon {
  margin-right: 20px;
  width: 10px;
}

.welcome {
  padding-top: 10px;
  color: rgb(46, 46, 46);
}

.searchbar {
  font-family: Overpass;
  font-size: 20px;
  background: none;
  color: #2b2b2b;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.searchbar-container {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  background: #dbdbdb;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
}
.search-btn {
  position: absolute;
  right: 13%;
  top: 116px;
  width: 10px;
  background: none;
  border: 0;
}

.google-sign-in {
  margin-top: 20vh;
  background: none !important;
  border: none !important;
}

.login-logo {
  background: #111111;
  padding: 10px;
}

.garage-owner {
  text-transform: uppercase;
}

.collapse-body {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.collapse-body:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
  margin: -20px;
}

.collapse.show {
  background: none;
}

/*
-----------------------------------HOME PAGE--------------------------
*/

.home-searchbar-container {
  /* background: rgb(231, 231, 231); */
  z-index: 1000;
  padding: 0px;
  position: fixed;
  left: 20%;
  top: 60px;
  width: 60%;
  margin: auto;
  margin-top: 0px;
  font-family: Overpass;
  border-radius:  0px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);

}
#home-searchbar {
  background: #FFF;
  font-size: 30px;
  border-radius:  0px;
}

.form-control:focus{
  outline: none;
}

*:focus {
  outline: none;
}

.btn-dropdown {
  background-color: #692115 !important;
  color: #FFF;
  border: none !important;
  border-radius:  0px !important;
}

#btn-searchbar {
  border-radius: 0px;
}

.car-search-form {
  width: 100%;
  margin-bottom: 0px;
  padding: 10px 30px;
  border-radius: 0px;
}

.car-search-form .form-row {
  margin-bottom: 10px;
}

#car-searchbar::placeholder {
  border-radius:  0px;
  color: rgba(97, 97, 97, 0.733);
}
/*
-----------------------------------MARKETPLACE--------------------------
*/
.marketplace-box {
  padding-top: 90px;
  margin-right: 50px;
  margin-left: 50px;
}

.marketplace-title {
  padding-top: 10px;
  padding-left: 20px;

  color: #c6b495;
  font-family: "Overpass", sans-serif;
  font-weight: 700;

  font-size: 25pt;
  height: 50px;
  text-align: left;
}

.car-price {
  font-family: "Overpass", sans-serif;
  font-size: 15pt;

  text-align: right;
  position: absolute;
  right: 0px;
  top: 10px;
  padding: 4px;
  color: #38993b;
  background: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.market-info {
  margin-top: -50px;
}

.market-icon {
  width: 15px;
  padding-left: 2px;
}
/*
-----------------------------------MARKETPLACE SEARCH--------------------------
*/

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
}

.market-searchbox {
  font-family: Overpass;
  width: 80%;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px;
  background-color: #565656;
  color: #fff;
}

/*
-----------------------------------EXPLORE--------------------------
*/

.manufacturer-card {
  font-family: Overpass;
  display: inline-block;

  font-weight: 700;
  background-color: #565656;
  color: #565656;
  width: 100px;
  height: 100px;

  padding: 5px;
  outline: none;
  transition-property: all;
  transition-duration: 0.25s;
}

.manufacturer-card:hover {
  width: 105px;
  background: #d1cfcf;
}

/*

-----------------------------------Buttons--------------------------
*/

.button {
  font-family: Overpass;
  font-weight: 300;
  width: 100px;
  background: #c6b495;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  transition-property: all;
  transition-duration: 0.25s;
}

.button:hover {
  background: #e3ceaa;
}

/*

-----------------------------------LOGIN FORM--------------------------
*/
.login-form {
  padding-top: 200px;
}

input:focus {
  outline: 0;
}

.login-input {
  font-family: Overpass;
  background: none;
  border-bottom-color: #c6b495;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: #fff;
  width: 300px;
  height: 30px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.garage-header {
  margin-top: 30px;
  margin-left: 45px;
  font-family: Overpass;
  color: rgb(0, 0, 0);
  text-align: left;
}

.add-car-btn {
  float: right;
  margin-right: 20px;
}