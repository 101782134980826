.insta-pic-container {
    display: inline-block;
    padding: 10px;
    background: rgb(236, 236, 236);
    margin-right: 10px;
    transition: all 2s;
    position: relative;
    margin-top: 15px;
}

#page-wrap {
    text-align: center;
}

.insta-pic {
    width: 300px;
}

.instagram-search {
    width: 300px !important;
}

.album-pic {
    display: inline-block;
    width: 300px;
    margin-right: 5px;
}

.album-pic-container {
    position: relative;
    display: inline-block;
}

.add-btn {
    position: absolute;
    bottom: 45px;
    right: 15px;
}

.album-add-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.album-btn {
    position: absolute;
    top: 15px;
    left: 15px;
    opacity: 0.8;
    z-index: 1000;
}