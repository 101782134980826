.car-display {
  margin-top: 100px;
}

.car-header {
  margin-top: 0px;
  scroll-behavior: smooth;
  text-align: center;
}

body::-webkit-scrollbar {
  display: none;
}

.car-header-overlay {
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 20vh;
  font-family: Overpass;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 8vw;
}

.car-header-year {
  font-weight: 200;
  height: 7vw;
}

.car-header-model {
  text-transform: uppercase;
  line-height: 7vw;
  padding-top: 26px;
}

a .car-header-user {
  color: #fff;
  display: inline-block;
  text-decoration: none;
  padding: 20px;
  transition: all 0.2s;
}

a .car-header-user:hover {
  background: rgba(167, 167, 167, 0.178);
  text-decoration: none !important;
}

.car-header-user {
  font-weight: 300;
  font-size: 20px;
}

.car-header-engine {
  padding-top: 30px;
}

.car-header-text {
  margin: auto;
  margin-top: 20px;
  width: 50vw;
  font-size: 15px;
  line-height: 25px;
}

.header-photo-container {
  z-index: 10;
  height: calc(100vh - 60px);
  min-height: 650px;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}

.car-modal-picture {
  width: 100%;
}

.header-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.test {
  padding-top: 100px;
  min-height: 100vh;
}

.car-footer {
  height: calc(100vh - 60px);
  background: #333333;
  color: rgb(66, 66, 66);
}

.left-footer {
  float: left;
  overflow: scroll;
  overflow-x: hidden;
  text-align: right;
  padding-left: 50px;
  padding-right: 50px;
  background: #dadada;
  height: calc(100vh - 60px);
}

.left-footer::-webkit-scrollbar {
  display: none;
}

.left-header {
  text-align: left;
  font-family: Overpass;
  margin-top: 20px;
  font-size: 50px;
  padding: 25px;
  line-height: 50px;
  text-transform: uppercase;
  /* border-left: solid 5px rgb(105, 33, 21); */
}

.footer-user {
  font-size: 20px;
}

.car-description {
  margin-top: 10px;
  padding: 20px 50px;
  text-align: left;
}
.like-button {
  transition: all 1s;
}

.like-button:hover {
  background: rgba(255, 255, 255, 0.199);
}

.right-footer {
  color: #000;
  overflow: scroll;
  overflow-x: hidden;
  background: #fff;
  text-align: center;
  height: calc(100vh - 60px);
}

.right-footer::-webkit-scrollbar {
  display: none;
}

.inlineBlock {
  display: inline-block;
}

.pic-container {
  display: table;
  width: 100%;
}

.picture-exp-small {
  display: inline-block;
  width: 350px;
  height: 350px;
  margin: 15px;
  transition: all 0.5s ease;
}

.picture-exp-large {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  transition: all 0.5s ease;
}

.modal-content {
  background-color: none;
}

.picture-modal .modal-body {
  padding: 0;
}

.month {
  width: 100%;
}
.timeline-header {
  font-size: 40px;
  margin-top: 20px;
}

.timeline {
  float: left;
  font-size: 20px;
  text-align: left;
  padding: 15px;
  width: 50px;
  border-right: solid 5px rgb(105, 33, 21);
}

.timeline-container {
  float: left;
}

.picture-container {
  float: right;
  width: calc(100% - 85px);
}

.car-header-container {
  height: 100%;
  scroll-behavior: smooth;
}

.header-button-container {
  background: rgba(0, 0, 0, 0.2);

  padding: 10px;
  padding-top: 15px;
  position: fixed;
  top: 100px;
  right: 20px;
}

.header-button {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-left: 25px;
  font-size: 3vw;
  color: #fff;
  transition: all 0.25s ease;
}

.header-button:hover {
  color: #a8a8a8;
}

@media screen and (max-width: 700px) {
  .header-button {
    font-size: 25px;
    right: 10px;
  }
}
@media screen and (min-width: 1000px) {
  .header-button {
    font-size: 50px;
  }
}

.down-button {
  position: absolute;
  bottom: 20px;
  color: #fff;
  font-size: 50px;
  transition: all 0.25s ease;
}

.down-button:hover {
  color: rgb(105, 33, 21);
}

.left {
  left: 30px;
}

.right {
  right: 30px;
}

#specs {
  margin-left: 0px;
}

.top-buttons {
  padding: 10px;
  position: fixed;
  top: 100px;
  z-index: 1000;
  right: 60px;
}

.like-button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  float: right;
  font-size: 2vw;
  transition: all 0.25s ease;
  padding: 10px;
  border-radius: 10px;
}

.fa-heart {
  transition: all 0.2s;
}

.follow-button {
  margin-top: 10px;
  padding: 10px;
  background: #262626;
  color: #fff;
}

.car-stats-row {
  margin-top: 10px;
  text-align: center;
}

.car-stats-row .car-card-stat {
  background: rgb(238, 238, 238);
  color: rgb(80, 80, 80);
}
