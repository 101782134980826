.card-container {
  margin-top: 130px;
  /* padding-top: 10px;
  overflow: scroll;
  overflow-x: hidden;
  height: calc(100vh - 180px); */
}

.car-card {
  background: #3a3a3a;
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  position: relative;
  height: 250px;
  width: 450px;
  display: inline-block;
  margin: 10px;
  outline: none;
  transition-property: all;
  transition-duration: 0.25s;
  border-bottom-style: solid;
  border-color: #fff;
  border-width: 5px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}

.card-overlay {
  top: 0px;
  position: absolute;
  z-index: 100;
  background: linear-gradient(to bottom, transparent, 80%, #000);
  height: 100%;
  width: 100%;
  transition: all 0.3s;
}

.card-photo {
  z-index: 100;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.car-card:hover .card-photo {
  transform: scale(1.1);
}

.car-title {
  font-family: "Overpass", sans-serif;
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15pt;
  text-align: left;
  line-height: 21px;
  padding-left: 10px;
}

.car-overlay-title {
  font-family: "Overpass", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: -15px;
  width: 320px;
  padding-bottom: 15px;
  padding-left: 5px;
  font-size: 15pt;
  text-align: left;
  line-height: 18px;
}

a .card-overlay {
  color: #fff;
}

.car-year {
  font-family: "Overpass", sans-serif;
  font-weight: 200;
}

.car-user {
  font-family: "Overpass", sans-serif;
  font-size: 12pt;
  font-weight: 300;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 0px;
  float: right;

}

.card-like {
  top: 10px;
  right: 10px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
  color: #ba6666;
  z-index: 1;
  padding: 5px;
  background: rgba(255, 255, 255, 0.685);
  font-size: 20px;
  border-radius: 10px;
}

.car-card:hover .card-like {
  opacity: 1;
}
