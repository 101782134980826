.nav-top {
    z-index: 900;
    background: #111111;
    text-align: center;
    height: 60px;
  }
  .navbar-home {
    height: 165px;
  }
  
  .navbar-items {
    display: inline-block;
  }
  
  #navbar-login {
    text-decoration: none;
    font-family: Overpass;
    font-weight: 600;
    text-align: left;
    letter-spacing: 1px;
    color: #fff;
    position: absolute;
    right: 100px;
    top: 20px;
  }
  
  .navbar-brand {
    margin-top: 0px;
    margin-left: 89px;
  }
  
  #gear {
    color: #fff;
    background: rgb(105, 33, 21);
    font-family: Overpass;
    font-weight: 200;
    font-size: 15pt;
    margin-top: 0px;
    margin-right: 5px;
    padding: 10px 7px;
    border-right: solid 2px #fff;
  }
  
  #heads {
    font-family: Overpass;
    color: #fff;
    font-weight: 900;
    font-size: 15pt;
  }

  .sign-in {
    float: right;
    margin-right: 20px;
  }

  .sign-out {
    float: right;
    margin-left: 60px;
  }

  .circular-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: blue;
    /* commented for demo
    float: left;
    margin-left: 125px;
    margin-top: 20px;
    */
    
    /*for demo*/
    display:inline-block;
    vertical-align:middle;
  }
  .circular-image img{
    width:100%;
  }

  .dropdown-menu {
    z-index: 99900000 !important;
  }


