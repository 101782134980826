.garage-car-card {
  margin: 15px;
  display: inline-block;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}

.garage-car-card .car-card {
  margin: 0;
  box-shadow: none;
  border: none;
}

.garage-car-card-footer {
  padding: 15px 10px;
  height: 180px;
  background: rgb(221, 221, 221);
}

.edit-car-btn {
  margin-top: 10px;
  float: right;
}

.car-card-stat {
  background: rgb(131, 131, 131);
  color: #FFF;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  border-radius: 2px;
}

.footer-row {
  margin-top: 10px;
}